import methods from '@/enums/methods';
import Thoth from '@/service/base/thoth';

class NtkService extends Thoth {
    store(
        file,
    ) {
        const formData = new FormData();

        formData.append('file', file);

        return this.fetch({
            url: '/ntk-id-file',
            data: formData,
            method: methods.POST,
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
    }

    getByNsuAndDate({
        nsu, date,
    }) {
        return this.fetch({
            url: 'ntk-id',
            params: {
                nsu, date,
            },
        });
    }
}

export default new NtkService();
