<template>
  <b-container>
    <b-tabs>
      <b-tab title="Importar">
        <Import />
      </b-tab>

      <b-tab title="Pesquisar NSU">
        <ViewNSU />
      </b-tab>
    </b-tabs>
  </b-container>
</template>

<script>
import {
    BContainer, BTabs, BTab,
} from 'bootstrap-vue';

import Import from './Import.vue';

import ViewNSU from './View.vue';

export default {
    components: {
        BTabs,
        BTab,
        BContainer,
        Import,
        ViewNSU,
    },
};
</script>
