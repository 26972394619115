<template>
  <b-container>
    <b-card>

      <validation-observer #default="{ invalid }">
        <b-form>
          <b-form-group>
            <validation-provider
              #default="{ errors }"
              name="NSU"
              rules="xlsx|required"
            >
              <b-form-file
                id="file"
                v-model="file"
                :state="errors.length > 0 ? false : null"
                placeholder="Arraste ou selecione um arquivo"
                drop-placeholder="Arraste o arquivo aqui."
                browse-text="Procurar"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-button
            variant="primary"
            :disabled="invalid"
            @click="importFile"
          >
            Importar Arquivo
          </b-button>
        </b-form>
      </validation-observer>
    </b-card>

    <b-card v-if="tableItems.length">
      <Table
        class="mt-2"
        :table-items="tableItems"
      />
    </b-card>
  </b-container>
</template>

<script>
import {
    BContainer, BForm, BButton, BFormGroup, BFormFile, BCard,
} from 'bootstrap-vue';

import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { DateTime } from 'luxon';
import Table from './Table.vue';
import NtkService from '@/service/ntk';

export default {
    components: {
        BCard,
        BContainer,
        BForm,
        ValidationProvider,
        ValidationObserver,
        BButton,
        BFormFile,
        BFormGroup,
        Table,
    },

    data() {
        return {
            file: null,
            tableItems: [],
        };
    },

    methods: {
        async importFile() {
            const response = this.confirmAnAction('Deseja realmente importar esse arquivo');

            if (!response) { return; }

            const { data, status } = await NtkService.store(this.file);

            if (status === 200) {
                this.modalSuccess('Arquivo importado com sucesso');
                this.tableItems = data;
                return;
            }

            this.modalError('Falha ao importar o arquivo');
        },

        formatDate(date) {
            return DateTime.fromJSDate(new Date(date)).toFormat('dd/MM/yyyy hh:mm:ss');
        },
    },
};
</script>
