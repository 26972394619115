<template>
  <b-table
    v-if="tableItems.length"
    responsive
    :items="tableItems"
    class="text-center"
    :fields="[
      'ntkId',
      'nsu',
      { key: 'holder', label: 'Portador'},
      { key: 'date', label: 'data'},
      { key: 'establishment', label: 'Estabelecimento'},
      { key: 'taxId', label: 'CNPJ'},
      { key: 'serialNumber', label: 'Número Serial' },
      { key: 'resultMessage', label: 'Mensagem'},
      'status',
      { key: 'receipt', label: 'Recibo' },
    ]"
  >
    <template #cell(date)="data">
      {{ formatDate(data.item.date) }}
    </template>

    <template #cell(taxId)="data">
      {{ data.item.taxId | tax_id }}
    </template>
  </b-table>
</template>

<script>
import {
    BTable,
} from 'bootstrap-vue';

import { DateTime } from 'luxon';

export default {
    components: {
        BTable,
    },

    props: {
        tableItems: {
            type: Array,
            default: () => [],
        },
    },

    methods: {
        formatDate(date) {
            return DateTime.fromJSDate(new Date(date)).toFormat('dd/MM/yyyy hh:mm:ss');
        },
    },
};
</script>
