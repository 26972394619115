var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.tableItems.length)?_c('b-table',{staticClass:"text-center",attrs:{"responsive":"","items":_vm.tableItems,"fields":[
    'ntkId',
    'nsu',
    { key: 'holder', label: 'Portador'},
    { key: 'date', label: 'data'},
    { key: 'establishment', label: 'Estabelecimento'},
    { key: 'taxId', label: 'CNPJ'},
    { key: 'serialNumber', label: 'Número Serial' },
    { key: 'resultMessage', label: 'Mensagem'},
    'status',
    { key: 'receipt', label: 'Recibo' } ]},scopedSlots:_vm._u([{key:"cell(date)",fn:function(data){return [_vm._v(" "+_vm._s(_vm.formatDate(data.item.date))+" ")]}},{key:"cell(taxId)",fn:function(data){return [_vm._v(" "+_vm._s(_vm._f("tax_id")(data.item.taxId))+" ")]}}],null,false,4156799385)}):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }