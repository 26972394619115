<template>
  <b-card>
    <validation-observer #default="{ invalid }">
      <b-form>

        <b-form-group
          label="NSU"
          label-for="nsu"
        >
          <validation-provider
            #default="{ errors }"
            name="NSU"
            rules="nsu|required"
          >
            <b-form-input
              id="nsu"
              v-model="nsu"
              placeholder="NSU"
              :state="errors.length > 0 ? false : null"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group
          label="Data da Transação"
          label-for="transaction-date"
        >
          <validation-provider
            #default="{ errors }"
            name="Data da Transação"
            rules="required"
          >
            <b-form-input
              id="transaction-date"
              v-model="date"
              type="date"
              :state="errors.length > 0 ? false : null"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <b-button
          variant="primary"
          :disabled="invalid"
          @click="fetch"
        >
          Pesquisar
        </b-button>
      </b-form>
    </validation-observer>
    <Table
      class="mt-2"
      :table-items="data"
    />
  </b-card>
</template>

<script>
import {
    BForm, BFormGroup, BButton, BFormInput, BCard,
} from 'bootstrap-vue';

import { ValidationObserver, ValidationProvider } from 'vee-validate';

import Table from './Table.vue';

import NtkService from '@/service/ntk';

import '@validations';

export default {
    components: {
        BForm, BFormGroup, BButton, BFormInput, BCard, ValidationObserver, ValidationProvider, Table,
    },

    data() {
        return {
            date: null,
            nsu: '',
            ntkId: '',
            data: [],
        };
    },

    watch: {
        nsu() {
            this.ntkId = '';
        },
        date() {
            this.ntkId = '';
        },
    },

    methods: {
        async fetch() {
            this.callLoading(true);
            const { data, status } = await NtkService.getByNsuAndDate({
                nsu: this.nsu,
                date: this.date,
            });
            this.callLoading(false);

            if (status === 200) {
                this.data = data;
            } else {
                this.modalError('Transacão não encontrada');
            }
        },
    },
};
</script>
